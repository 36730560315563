/*
TODO:
https://github.com/gregallensworth/L.Control.Credits
https://github.com/makinacorpus/Leaflet.Spin
 - multilinie
*/
import 'promise-polyfill';
import ymaps from 'ymaps';
const proj4 = require('proj4leaflet/lib/proj4-compressed');
import 'proj4leaflet/src/proj4leaflet';
import './TileLayer.ProjWMTS.js';
import 'leaflet-modal/dist/L.Modal.min';

const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const osmAttribution = 'Dane do mapy &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>';

export function in_array(elem, a) {
    if (a === null || !(a instanceof Array)) return false;

    for (var i = 0, len = a.length; i < len; i++) {
        if (a[i] === elem) return true;
    }
    return false;
}



function setPageTitleAndLogo(config) {
    var $ = require('jquery');
    if (config.hasOwnProperty('page-title')) {
        var title = config['page-title'];
        $('span.header-title').text(title);
    }
    if (config.hasOwnProperty('page-header-logo')) {
        var logo = config['page-header-logo'];
        $('a.logo-page>img').attr('src', logo);
    }
    if (config.hasOwnProperty('page-header-url')) {
        var url = config['page-header-url'];
        $('a.logo-page').attr('href', url);
    } else {
        $('a.logo-page').attr('href', '/');
    }
}

export function createMap(dir) {
    import('leaflet').then(function (L) {
        var $ = require('jquery');
        require('leaflet/dist/leaflet.css');

        L.Icon.Default.imagePath = '.';

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });

        $.getJSON(dir + '/config.json').then(function (config) {
            setPageTitleAndLogo(config);

            var bounds = new L.LatLngBounds(
                new L.LatLng(config.mapExtent[0], config.mapExtent[1]),
                new L.LatLng(config.mapExtent[2], config.mapExtent[3])
            );

            import('leaflet-gesture-handling').then(GestureHandling => {
                require('leaflet-gesture-handling/dist/leaflet-gesture-handling.css');
                L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling.GestureHandling);
            });

            var map = L.map('map', {
                center: config.mapCentre,
                zoom: config.mapStartLevel,
                maxBounds: bounds,
                maxZoom: config.mapMaxZoom,
                gestureHandling: true
            });

            // menu
            import('leaflet-groupedlayercontrol').then(function () {
                require('leaflet-groupedlayercontrol/dist/leaflet.groupedlayercontrol.min.css');
                var panel = L.control.groupedLayers();
                panel.addTo(map);
                var baseLayers = baseLayersFromConfig(config, panel);
                for (var key in baseLayers) {
                    baseLayers[key].addTo(map);
                    break;
                }
                overLayersFromConfig(config, map, panel);
            });

            // navigation bar
            import("leaflet-navbar").then(function () {
                require('leaflet-navbar/Leaflet.NavBar.css')
                L.control.navbar({
                    position: 'topleft'
                }).addTo(map)
            });

            // measure tool
            import("leaflet-measure/dist/leaflet-measure.pl").then(function () {
                require('leaflet-measure/dist/leaflet-measure.css');
                L.control.measure({
                    primaryLengthUnit: 'meters',
                    secondaryLengthUnit: 'kilometers',
                    primaryAreaUnit: 'sqmeters',
                    secondaryAreaUnit: 'hectares',
                    position: 'topleft',
                    activeColor: 'green',
                    completedColor: 'red'
                }).addTo(map);
            });

            // geocoder
            import("leaflet-control-geocoder").then(function () {
                require('leaflet-control-geocoder/dist/Control.Geocoder.css');
                L.Control.geocoder({
                    position: 'topleft',
                    placeholder: "Znajdź adres...",
                    errorMessage: "Nic nie znaleziono...",
                    geocoder: new L.Control.Geocoder.Nominatim()
                }).addTo(map);
            });

            // skala
            L.control.scale({
                position: 'bottomleft',
                maxWidth: 100,
                metric: true,
                imperial: false,
                updateWhenIdle: false
            }).addTo(map);

            // watermark
            if (!config.hasOwnProperty('watermark') || config.watermark !== false) {
                L.Control.Watermark = L.Control.extend({
                    onAdd: function (map) {
                        var img = L.DomUtil.create('img');
                        L.DomUtil.addClass(img, 'logo-watermark');
                        if (config.hasOwnProperty('watermark') && typeof config.watermark === 'string') {
                            img.src = config.watermark;
                        } else {
                            img.src = require('../img/logo_axigis.png');
                        }
                        return img;
                    },
                    onRemove: function (map) {
                        // Nothing to do here
                    }
                });
                L.control.watermark = function (opts) {
                    return new L.Control.Watermark(opts);
                };
                L.control.watermark({
                    position: 'bottomleft'
                }).addTo(map);
            }

            // coordinates
            import('leaflet.coordinates/dist/Leaflet.Coordinates-0.1.5.min').then(function (coordinates) {
                require('leaflet.coordinates/dist/Leaflet.Coordinates-0.1.5.css')
                L.control.coordinates({
                    position: "bottomright",
                    useDMS: true,
                    labelTemplateLat: "N {y}",
                    labelTemplateLng: "E {x}",
                    useLatLngOrder: true
                }).addTo(map);
            });

            // minimap
            import('leaflet-minimap').then(function (minimap) {
                require('leaflet-minimap/dist/Control.MiniMap.min.css');

                var osmMiniMap = L.tileLayer(osmUrl, {
                    attribution: osmAttribution,
                    minZoom: 2,
                    maxZoom: 9
                })
                new minimap(osmMiniMap, {
                    toggleDisplay: true
                }).addTo(map);
            });

            import('leaflet-hash').then(() => {
                L.hash(map);
            })

            // startup warning
            if (config['warning-show']) {
                require('leaflet-modal/dist/leaflet.modal.min.css');
                
                map.fire('modal', {
                    title: 'Uwaga',
                    content: config['warning-msg'],
                    template: ['<div class="modal-header"><h2>{title}</h2></div>',
                        '<hr>',
                        '<div class="modal-body">{content}</div>',
                        '<div class="modal-footer">',
                        '<button class="{OK_CLS}">{okText}</button>',
                        '</div>'
                    ].join(''),

                    okText: 'Ok',
                    OK_CLS: 'modal-ok',

                    width: 400,

                    onShow: function (evt) {
                        var modal = evt.modal;
                        L.DomEvent
                            .on(modal._container.querySelector('.modal-ok'), 'click', function () {
                                modal.hide();
                            })
                    }
                });

            };
        });
    });
}

function overLayersFromConfig(config, map, panel) {
    require('leaflet.markercluster/dist/MarkerCluster.css');
    require('leaflet.markercluster/dist/MarkerCluster.Default.css');
    require('leaflet.markercluster');
    require('leaflet.featuregroup.subgroup');

    var searchLayers = L.layerGroup();
    var cluster = L.markerClusterGroup().addTo(map);

    var menuNameFromConfig = function (lprop, name) {
        if (lprop.hasOwnProperty('style') && lprop.style.hasOwnProperty('iconUrl')) {
            return '<span><img style="vertical-align:middle" src="' + lprop.style.iconUrl + '"><span style="">' + name + '</span></span>';
        }
        return name;
    }
    var jsonStyleFromConfig = function (L, lprop) {
        const $ = require('jquery');

        var axiMapTooltip = null;
        var opracowania_opisy = null;

        if (lprop.hasOwnProperty('popup') && lprop.popup.hasOwnProperty('json')) {
            opracowania_opisy = $.ajax({type: "GET", url: lprop.popup.json, dataType:'json', async: false}).responseJSON
        }

        if (lprop.hasOwnProperty('popup') && lprop.popup.hasOwnProperty('src')) {
            let func = $.ajax({type: "GET", url: lprop.popup.src, dataType:'txt' , async: false}).responseText
            axiMapTooltip = new Function(func)();
        }
        
        var o = {
            filter: function (feature) {
                return !lprop.hasOwnProperty('filter') || feature.properties[lprop.filter[0]] === lprop.filter[1];
            },
            onEachFeature: function (f, layer) {
                if (lprop.hasOwnProperty('search') && f.properties.hasOwnProperty(lprop.search)) {
                    f.properties.search = f.properties[lprop.search];
                }
                if (lprop.hasOwnProperty('popup')) {
                    if (Array.isArray(lprop.popup) || typeof lprop.popup === 'boolean') {
                        var pop = '';
                        for (var attr in f.properties) {
                            if (lprop.popup === true || in_array(attr, lprop.popup)) { // todo remove 'search'
                                pop += '<b>' + attr + '</b>: ' + f.properties[attr] + '<br>';
                            }
                        }
                        layer.bindPopup(pop, f);
                    } else if (lprop.popup.hasOwnProperty('type') && lprop.popup.type === 'function') {
                        if (typeof axiMapTooltip === 'function') {
                            layer.bindPopup(axiMapTooltip(f, opracowania_opisy))
                        }
                    }
                }
            }
        };

        if (lprop.symbol === 'circle') {
            o.pointToLayer = function (feature, latlng) {
                return L.circleMarker(latlng, lprop.style);
            };
        } else if (lprop.symbol === 'icon') {
            o.pointToLayer = function (feature, latlng) {
                return L.marker(latlng, {
                    icon: L.icon(lprop.style)
                });
            };
        } else {
            o.style = function (feature) {
                return lprop.style;
            };
        }
        return o;
    }

    var _f = function (c, group, name, panel, cluster) {
        var $ = require('jquery');

        var layerProperties = c[group][name];
        $.getJSON(layerProperties.geom).then(geoJsonData => {
            var geoJsonLayer = L.geoJson(geoJsonData, jsonStyleFromConfig(L, layerProperties));
            if (layerProperties.cluster == true) {
                var subGroup = L.featureGroup.subGroup(cluster);
                subGroup.addLayer(geoJsonLayer);
                if (layerProperties.start) {
                    subGroup.addTo(map);
                }
                if (layerProperties.menu) {
                    panel.addOverlay(subGroup, menuNameFromConfig(layerProperties, name), group);
                }
            } else {
                if (layerProperties.start) {
                    geoJsonLayer.addTo(map);
                }
                if (layerProperties.menu) {
                    panel.addOverlay(geoJsonLayer, menuNameFromConfig(layerProperties, name), group);
                }
                if (layerProperties.hasOwnProperty('search') && layerProperties.search) {
                    // todo searchLayers.addLayer(geoJsonLayer);
                }
            }
        });
    }

    for (var group in config.layers) {
        if (config.layers.hasOwnProperty(group)) {
            for (var name in config.layers[group]) {
                _f(config.layers, group, name, panel, cluster);
            }
        }
    }

    // search
    if (searchLayers.getLayers().length > 0) {
        import("leaflet-search").then(function () {
            require('leaflet-search/dist/leaflet-search.src.css');
            L.control.search({
                layer: searchLayers,
                initial: false,
                hideMarkerOnCollapse: true,
                propertyName: 'search'
            }).addTo(map);
        });
    }
}

function baseLayersFromConfig(config, panel) {
    proj4.defs('EPSG:2180', '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs');
    var crs = new L.Proj.CRS(
        "EPSG:2180",
        "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs",
        {});

    var baseLayers = {};

    for (var k in config.basemaps) {
        if (config.basemaps.hasOwnProperty(k) && config.basemaps[k] === true) {
            switch (k) {
                case "OpenStreetMap":
                    addTileLayer(baseLayers, panel, 'OpenStreetMap', osmUrl, {
                        maxZoom: 17,
                        minZoom: 5,
                        attribution: osmAttribution
                    });
                    break;
                case "OpenTopoMap":
                    const OpenTopoMapUrl = 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png';
                    const OpenTopoMapAttribution = 'Dane do mapy: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="https://viewfinderpanoramas.org">SRTM</a> | Styl mapy: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)';
                    addTileLayer(baseLayers, panel, 'OpenTopoMap', OpenTopoMapUrl, {
                        maxZoom: 17,
                        minZoom: 5,
                        attribution: OpenTopoMapAttribution
                    });
                    break;
                case "geoportal.gov.pl:G2_MOBILE":
                    const attributionG2_MOBILE = 'Dane do mapy: &copy; <a href="https://geoportal.gov.pl/">geoportal.gov.pl</a>';
                    const urlG2_MOBILE = 'https://mapy.geoportal.gov.pl/wss/service/WMTS/guest/wmts/G2_MOBILE_500'
                    addWmtsLayer(baseLayers, panel, 'Geoportal.gov.pl (Mobile 500)', urlG2_MOBILE, {
                        crs: crs,
                        format: 'image/png',
                        tileSize: 512,
                        version: '1.0.0',
                        transparent: true,
                        origin: [850000.0, 100000.0],
                        scales: [30238155.714285716, 15119077.857142858, 7559538.928571429, 3779769.4642857146, 1889884.7321428573, 944942.3660714286, 472471.1830357143, 236235.59151785716, 94494.23660714286, 47247.11830357143, 23623.559151785714, 9449.423660714287, 4724.711830357143, 1889.8847321428573, 944.9423660714286, 472.4711830357143],
                        tilematrixSet: 'EPSG:2180',
                        opacity: 1.0,
                        crossOrigin: true,
                        minZoom: 5,
                        attribution: attributionG2_MOBILE
                    })
                    //https://mapy.geoportal.gov.pl/wss/service/WMTS/guest/wmts/G2_MOBILE_500?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&FORMAT=image%2Fpng&TILEMATRIXSET=EPSG:2180&TILEMATRIX=EPSG:2180:9&TILEROW=68&TILECOL=82
                    break;

                case 'Google:hybrid':
                    addGoogleLayer(config, baseLayers, panel, 'Google Maps (hybrid)', 'hybrid');
                    break;
                case 'Google:roadmap':
                    addGoogleLayer(config, baseLayers, panel, 'Google Maps (roads)', 'roadmap');
                    break;
                case 'Google:terrain':
                    addGoogleLayer(config, baseLayers, panel, 'Google Maps (terrain)', 'terrain');
                    break;
                case 'Google:satellite':
                    addGoogleLayer(config, baseLayers, panel, 'Google Maps (satellite)', 'satellite');
                    break;

                case 'Bing:Aerial':
                    addBingLayer(config, baseLayers, panel, 'Bing (Aerial)', 'Aerial');
                    break;
                case 'Bing:AerialWithLabels':
                    addBingLayer(config, baseLayers, panel, 'Bing (AerialWithLabels)', 'AerialWithLabels');
                    break;
                case 'Bing:Birdseye':
                    addBingLayer(config, baseLayers, panel, 'Bing (Birdseye)', 'Birdseye');
                    break;
                case 'Bing:BirdseyeWithLabels':
                    addBingLayer(config, baseLayers, panel, 'Bing (BirdseyeWithLabels)', 'BirdseyeWithLabels');
                    break;
                case 'Bing:Road':
                    addBingLayer(config, baseLayers, panel, 'Bing (Road)', 'Road');
                    break;

                case 'Yandex:map':
                    addYandexLayer(baseLayers, panel, 'Yandex', 'map');
                    break;
                case 'Yandex:satellite':
                    addYandexLayer(baseLayers, panel, 'Yandex (satellite)', 'satellite');
                    break;
                case 'Yandex:hybrid':
                    addYandexLayer(baseLayers, panel, 'Yandex (hybrid)', 'hybrid');
                    break;

                case 'ESRI World Street Map':
                    addTileLayer(baseLayers, panel, 'ESRI World Street Map',
                        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
                        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
                    });
                    break;
            }
        }
    }
    return baseLayers;
}

function addGoogleLayer(config, layers, panel, name, type) {
    if (!config.hasOwnProperty('googleApiKey') || config.googleApiKey == "") return;

    const { Loader } = require('google-maps')
    const options = { LANGUAGE: 'pl', REGION: 'PL', VERSION: '3.35' };
    const loader = new Loader(config.googleApiKey, options);
    loader.load().then(function (google) {
        require('leaflet.gridlayer.googlemutant');
        var layer = L.gridLayer.googleMutant({
            type: type
        });
        panel.addBaseLayer(layer, name);
        layers[name] = layer;
    })
}

function addBingLayer(config, layers, panel, name, type) {
    if (!config.hasOwnProperty('bingApiKey') || config.bingApiKey == "") return;
    require('leaflet-plugins/layer/tile/Bing');
    var layer = L.bingLayer(config.bingApiKey, {
        type: type
    });
    panel.addBaseLayer(layer, name);
    layers[name] = layer;
}

function addYandexLayer(layers, panel, name, type) {
    ymaps
        .load('https://api-maps.yandex.ru/2.1/?lang=en_US')
        .catch(
            error => console.log('Failed to load Yandex Maps', error)
        );
    require('leaflet-plugins/layer/tile/Yandex');
    var layer = new L.Yandex(type);
    panel.addBaseLayer(layer, name);
    layers[name] = layer;
}

function addTileLayer(layers, panel, name, url, options) {
    var layer = L.tileLayer(url, options);
    panel.addBaseLayer(layer, name);
    layers[name] = layer;
}

function addWmtsLayer(layers, panel, name, url, options) {
    var layer = L.tileLayer.projwmts(url, options);
    panel.addBaseLayer(layer, name);
    layers[name] = layer;
}
