var $ = require('jquery');
var in_array = require('./js/aximap').in_array
var getParamByName = require('get-param-by-name');

var param = getParamByName('m', window.location.href);
const projects = {
  'mot' : 'Motorowa Odznaka Turystyczna',
  'szlak-piastowski': 'Szlak Piastowski',
  'radom-mpu': 'Radom MPZP',
  //'rajgrod' : 'Rajgród - mapa turystyczna',
  //'demo': 'Mapa testowa',
}


if (in_array(param, Object.keys(projects))) {
  var m = require('./js/aximap');
  m.createMap('./maps/' + param);
} else {
  var div = $('#map');
  var ul = document.createElement('ul');

  for (var id in projects) {
    var li = document.createElement('li');
    var a = document.createElement('a');
    a.setAttribute('href', '?m=' + id);
    a.innerText = projects[id];
    li.appendChild(a);
    ul.appendChild(li);
  }
  div.html('');
  div.append(ul);
}
